define([
    'santa-components',
    'lodash',
    'componentsCore',
    'linkBar/linkBarItem/linkBarItem'
], function (santaComponents, _, componentsCore, linkBarItem) {
    'use strict';

    const ORIENTATION = {
        'HORIZ': 'HORIZ',
        'VERTICAL': 'VERTICAL'
    };

    function calculateMobileDimensions(numOfItems, iconSize, spacing, isHorizontal, totalItemSize) {
        if (numOfItems === 0) {
            return {
                width: 5,
                height: iconSize
            };
        }
        let width, height, itemsInLine;

        const itemSize = iconSize + spacing;
        if (isHorizontal) {
            width = Math.min(totalItemSize, 300);
            itemsInLine = Math.floor((width + spacing) / itemSize);
            height = Math.ceil(numOfItems / itemsInLine) * iconSize;
        } else {
            width = iconSize;
            height = totalItemSize;
        }

        return {
            width,
            height
        };
    }

    function getChildStyle({compProp, isResponsive}) {
        const isHorizontal = compProp.orientation === ORIENTATION.HORIZ;
        const spacing = compProp.spacing;

        const margins = {
            marginRight: isHorizontal ? spacing : 0,
            marginBottom: isHorizontal ? 0 : spacing
        };

        if (isResponsive) {
            return margins;
        }

        return _.assign({
            display: isHorizontal ? 'inline-block' : 'block'
        }, margins);
    }

    const getLinkBarStyles = ({compData, compProp, isMobileView}) => {
        const numOfItems = compData.items.length;
        const iconSize = compProp.iconSize;
        const spacing = compProp.spacing;
        const totalItemSize = numOfItems * (iconSize + spacing) - spacing; // eslint-disable-line no-mixed-operators
        const isHorizontal = compProp.orientation === ORIENTATION.HORIZ;

        if (isMobileView) {
            return calculateMobileDimensions(numOfItems, iconSize, spacing, isHorizontal, totalItemSize);
        }

        if (numOfItems === 0) {
            return undefined;
        }

        return {
            width: isHorizontal ? totalItemSize : iconSize,
            height: isHorizontal ? iconSize : totalItemSize
        };
    };

    /**
     * @class components.LinkBar
     * @extends {core.skinBasedComp}
     */
    return {
        displayName: 'LinkBar',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],

        propTypes: _.assign({
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            isResponsive: santaComponents.santaTypesDefinitions.RendererModel.isResponsive,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired
        }, santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(linkBarItem)),

        getInitialState() {
            const state = {};
            if (this.props.isMobileView) {
                state.$mobile = 'mobileView';
            }
            return state;
        },

        getChildren() {
            return _.map(this.props.compData.items, function (item, index) {
                return this.createChildComponent(item, 'wysiwyg.viewer.components.LinkBarItem', 'imageItem', {
                    itemStyle: getChildStyle(this.props),
                    ref: index,
                    id: `${this.props.id + index}image`, // This is how linkBarLayout builds the id,
                    parentId: this.props.id
                });
            }.bind(this));
        },

        getItemsContainerStyle() {
            return this.props.isResponsive ?
                {
                    flexDirection: this.props.compProp.orientation === ORIENTATION.HORIZ ? 'row' : 'column'
                } :
                {};
        },

        getRootStyles() {
            return this.props.isResponsive ?
                {} :
                getLinkBarStyles(this.props);
        },

        getSkinProperties() {
            return {
                '': _.assign({
                    'data-is-responsive': this.props.isResponsive,
                    style: this.getRootStyles()
                }, !this.props.isResponsive && {
                    'data-hide-prejs': true
                }),
                itemsContainer: {
                    'aria-label': 'Social bar',
                    style: this.getItemsContainerStyle(),
                    children: this.getChildren()
                }
            };
        }
    };
});
